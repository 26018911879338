<template>
    <div class="page">
        <div class="page-header pd">
            <sc-button icon="chevron-left" @click="$router.push(`/app/${resource.route}/index`)" :disabled="uploading">Back</sc-button>
        </div>
        <div class="pd">
            <sc-form :form="form" @submit="submit()" v-if="form">
                <template #field-files>
                    <!-- Input Select & Drop -->
                    <sc-form-field :form="form" field="files" name="Files*">
                        <div class="drop-area">
                            <div class="area-text">
                                Click to select or drop a media file
                            </div>
                            <input @change="loadFiles" type="file" multiple class="files-input"
                                   :accept="acceptTypes"/>
                        </div>
                    </sc-form-field>

                    <!-- PREVIEWS -->
                    <sc-form-field name="Media Preview" v-if="previews.length > 0">
                        <div class="preview-area">
                            <div class="preview" v-for="(p,i) in form.fields.files">

                                <template v-if="previews[i]">
                                    <video v-if="previews[i].type.includes('video')" controls>
                                        <source :src="previews[i].src" :type="previews[i].type"/>
                                    </video>
                                    <img v-if="previews[i].type.includes('image')" :src="previews[i].src"/>
                                </template>
                                <div class="name">
                                    {{ p.name }}
                                </div>
                            </div>
                        </div>
                    </sc-form-field>
                </template>

                <template #field-name>
                    <div style="display:none;" v-if="form.fields.files.length !== 1"></div>
                </template>

                <template #field-description>
                    <sc-form-field :form="form" field="description">
                        <textarea v-model="form.fields.description"/>
                    </sc-form-field>
                </template>

                <template #buttons>
                    <div class="pdw">
                        <sc-button :disabled="uploading" :running="uploading" @click="submit">
                            <awesome-icon icon="save" class="icon"/>
                            Submit
                        </sc-button>
                    </div>
                </template>

            </sc-form>
        </div>
    </div>

</template>

<script>

export default {
  name: "new",

  props: {
    passed: {
      required: true,
    }
  },

  data() {
    return {
      words: '',
      form: null,
      previews: [],
      uploading: false,
      acceptTypes: "image/jpg,image/jpeg,image/gif,image/svg,image/png,video/mp4,video/mp4v,video/mpg4,video/mpeg,video/mpg,video/mov"
    }
  },

  computed: {
    resource() {
      return this.passed
    }
  },

  mounted() {
    this.form = this.$sform.createForm({
      name: `New ${this.resource.name}`,
      url: this.resource.api,
      fields: {
        files: {
          name: 'Files*',

        },
        name: {},
        description: {}
      },
      success: () => {
        this.$notify.success(`${this.resource.name} added`)
        this.$router.push(`/app/${this.resource.route}/index`)
      }
    })
  },

  methods: {
    submit() {
      this.uploading = true;
      let f = new FormData();

      for (let i = 0; i < this.form.fields.files.length; i++) {
        let file = this.form.fields.files[i];
        f.append('files[' + i + ']', file);
      }

      if (this.form.fields.name) f.append('name', this.form.fields.name);
      f.append('description', this.form.fields.description);

      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Accept': 'application/json'
        }
      };

      this.$talker.api.post('media', f, config)
        .then((res) => {
          this.$notify.success(`${this.resource.name} added`)
          this.$router.push(`/app/${this.resource.route}/index`);
        })
        .catch((err) => {
          this.uploading = false;
          if (!err.response) {
            this.$notify.error('Something went wrong.');
          } else {

            let message = err.response.data.message;
            if (err.response.status === 413) {
              message = "Maximum upload size reached, try uploading fewer files at once"
            } else if (err.response.data.errors && err.response.data.errors['files.0']) {
              message += `: ${err.response.data.errors['files.0'][0]}`
            }

            this.$notify.error(message)
          }
        })
    },

    loadFiles(event) {
      this.previews = [];
      this.form.fields.files = [];

      // prepare previews
      // count files
      let addedCount = 0;
      for (let i = 0; i < event.target.files.length; i++) {

        // Only process valid file types
        if (this.acceptTypes.includes(event.target.files[i].type)) {
          addedCount++;
          this.getPreview(event.target.files[i])
            .then((preview) => {
              this.previews.push(preview)
              this.form.fields.files.push(event.target.files[i])
            })

        } else {
          this.$notify.error("File " + event.target.files[i].name + " not accepted.");
        }

        if (i === event.target.files.length -1) {
          if (addedCount === 1) {
            this.form.fields.name = event.target.files[0].name;
          } else {
            if (typeof this.form.fields.name !== 'undefined') {
              this.form.fields.name = undefined;
            }
          }
        }
      }
    },

    getPreview(f) {
      return new Promise((resolve) => {
        let r = new FileReader();
        r.onload = () => {
          resolve({type: f.type, src: r.result})
        }
        r.readAsDataURL(f);
      })
    }
  }
}
</script>

<style scoped lang="scss">

.drop-area {
    width: 100%;
    height: 200px;
    border: 2px $color-primary dashed;
    position: relative;
    cursor: pointer;

    .area-text {
        font-size: 1.5em;
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
        transform: translateY(-50%);
        text-align: center;
    }

    .files-input {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        opacity: 0;
    }
}

.preview-area {
    //text-align: center;
    .preview {
        display: inline-block;
        width: 200px;
        max-width: 200px;
        text-align: center;
        overflow: hidden;
        border: 1px solid #e2e2e2;
        margin: .5em;

        img, video {
            max-width: inherit;
            max-height: 150px;
            object-fit: fill;
        }

        .name {
            margin: 0 auto;
            overflow: hidden;
            height: 1.5em;
            max-width: 80%;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

</style>